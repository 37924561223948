import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loader from './../Loader'; 
const Home = lazy(() => import('./Home'));
const Services = lazy(() => import('./Services'));
const About = lazy(() => import('./About'));
const Career = lazy(() => import('./Career'));
const LandingService2 = lazy(() => import('./COMPONENTS/LandingService/LandingService2/LandingService2'));
const ErrorPage = lazy(() => import('./ErrorPage'));

function Connect() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' index element={<Suspense fallback={<Loader />}><Home /></Suspense>} />
          <Route path='/Service' element={<Suspense fallback={<Loader />}><Services /></Suspense>} />
          <Route path='/About' element={<Suspense fallback={<Loader />}><About /></Suspense>} />
          <Route path='/Career' element={<Suspense fallback={<Loader />}><Career /></Suspense>} />
          <Route path='/pureland' element={<Suspense fallback={<Loader />}><LandingService2 /></Suspense>} />
          <Route path='/notfound' element={<Suspense fallback={<Loader />}><ErrorPage /></Suspense>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default Connect;
