import React, { useEffect, useState } from 'react';
import './App.css';
import Connect from './PTINNOVATIONS/Connect';

function App() {


  return (
    <div>
    
        <Connect />
    
    </div>
  );
}

export default App;
