import React from 'react';
import './Loader.css';

const Loader = () => {
  return (
    <div class="loadse">
     <div class="loader"></div>
     <h6 className='text-white'>Wait a second.....</h6>

    </div>
   
  );
}

export default Loader;
